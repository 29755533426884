.row1 {
  font-size: x-large;
  font-weight: bold;
}

.row2 {
  font-size: 30px;
}

.percent {
  font-size: x-large;
}

.capitalTotal {
  margin-left: 15px;
}

.type {
  text-transform: capitalize;
}

.typeMobile {
  font-size: 25px;
  text-transform: capitalize;
}

.colPrice {
  text-align: right;
  white-space: nowrap;
}

.colPercent {
  text-align: left;
  white-space: nowrap;
  padding-left: 2% !important;
}

.rowDown {
  height: 100%;
}

.capitalField {
  text-transform: capitalize;
  white-space: nowrap;
}
