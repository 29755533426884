.tab {
  max-height: 100px;
  /* color: red; */
}

.tab:hover {
  background-color: var(--bs-gray-300);
}

.username {
  text-transform: capitalize;
}

.button {
  width: 70px;
}

.toggles {
  padding-right: 1%;
}
