.arrow {
  cursor: pointer;
}

.sorticon {
  padding-left: 10px;
}
.sortbutton {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.entities td {
  /* padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px; */
  /* color: aqua; */
  /* border: dashed; */
}

.table {
  /* border-collapse: inherit;
  border-spacing: 0 10px; */
}

.row {
  /* font-style: oblique; */
  font-style: italic;
}

.firstrow {
  border-bottom-style: solid;
  border-bottom-width: medium !important;
}

/* .middle {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
} */

.edit {
  text-align: center;
  width: 2%;
}
