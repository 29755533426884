.chart {
  /* padding: 0 5%; */
  width: 75% !important;
  height: 75% !important;
  margin: auto;
}

.buttonGroup {
  margin: 0 10%;
}

.chartBox {
  margin-bottom: 1%;
  display: flex;
  flex-direction: column;
}

.buttonGroup button {
  margin: 1% 1%;
}
